import React from "react";
// Config
import { EMPLOYEE_SUBPAGES, PAGES, uriHelper } from "../../../config";

import Breadcrumbs from "@component/Breadcrumbs";
import { UserRightsObjects, UserRightsPages } from "@model/Rights";
// Views
import Dependent from "@view/Employee/Dependent";
import DependentCreateModal from "@view/Employee/Dependent/Create";
import DependentEditModal from "@view/Employee/Dependent/Edit";
import DependentShowModal from "@view/Employee/Dependent/Show";
import HealthInsurance from "@view/Employee/HealthInsurance";
import HealthInsuranceCreateModal from "@view/Employee/HealthInsurance/Create";
import HealthInsuranceEditModal from "@view/Employee/HealthInsurance/Edit";
import HealthInsuranceShowModal from "@view/Employee/HealthInsurance/Show";
import Notes from "@view/Employee/Notes";
import NoteCreateModal from "@view/Employee/Notes/Create";
import NoteEditModal from "@view/Employee/Notes/Edit";
import NoteShowModal from "@view/Employee/Notes/Show";
import EmployeeShow from "@view/Employee/Show";
import BaseRouter from "../../Base";
import ContactInformations from "./ContactInformations";
import Contracts from "./Contracts";
import PersonalInformations from "./PersonalInformations";
import EvidenceState from "@view/Employee/EvidenceState";
import EvidenceStateCreateModal from "@view/Employee/EvidenceState/Create";
import Trainings from "@view/Employee/Trainings";
import TrainingShowModal from "@view/Employee/Trainings/Show";
import TrainingCreateModal from "@view/Employee/Trainings/Create";
import TrainingEditModal from "@view/Employee/Trainings/Edit";

// Props
interface EmployeeDetailProps {
  match: {
    params: {
      user_id: number;
    };
  };
}

export const employeeRoutes: IAppRoute[] = [
  {
    path: uriHelper.show_employees_uri(),
    exact: true,
    redirect: match => uriHelper.employees_contracts(match.user_id),
  },
  {
    path: uriHelper.employees_personal_data_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.${EMPLOYEE_SUBPAGES.PERSONAL_DATA}.list`,
    component: PersonalInformations,
  },
  {
    path: uriHelper.employees_contracts_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.${EMPLOYEE_SUBPAGES.CONTRACT}.list`,
    component: Contracts,
  },
  {
    path: uriHelper.employees_contact_informations_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.contact_information.list`,
    component: ContactInformations,
  },
  {
    path: uriHelper.employees_evidence_states_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.${EMPLOYEE_SUBPAGES.EVIDENCE_STATE}.list`,
    component: EvidenceState,
  },
  {
    path: uriHelper.new_employees_evidence_states_uri(),
    exact: true,
    modalComponent: EvidenceStateCreateModal,
  },
  {
    path: uriHelper.employees_health_insurances_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.${EMPLOYEE_SUBPAGES.HEALTH_INSURANCE}.list`,
    component: HealthInsurance,
  },
  {
    path: uriHelper.new_employees_health_insurances_uri(),
    exact: true,
    modalComponent: HealthInsuranceCreateModal,
  },
  {
    path: uriHelper.edit_employees_health_insurances_uri(),
    exact: true,
    modalComponent: HealthInsuranceEditModal,
  },
  {
    path: uriHelper.show_employees_health_insurances_uri(),
    exact: true,
    modalComponent: HealthInsuranceShowModal,
  },
  {
    path: uriHelper.employees_dependents_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.dependent.list`,
    component: Dependent,
  },
  {
    path: uriHelper.new_employees_dependents_uri(),
    exact: true,
    modalComponent: DependentCreateModal,
  },
  {
    path: uriHelper.edit_employees_dependents_uri(),
    exact: true,
    modalComponent: DependentEditModal,
  },
  {
    path: uriHelper.show_employees_dependents_uri(),
    exact: true,
    modalComponent: DependentShowModal,
  },
  {
    path: uriHelper.employees_notes_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.note.list`,
    component: Notes,
  },
  {
    path: uriHelper.new_employees_notes_uri(),
    exact: true,
    modalComponent: NoteCreateModal,
  },
  {
    path: uriHelper.edit_employees_notes_uri(),
    exact: true,
    modalComponent: NoteEditModal,
  },
  {
    path: uriHelper.show_employees_notes_uri(),
    exact: true,
    modalComponent: NoteShowModal,
  },
  {
    path: uriHelper.employees_trainings_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.training.list`,
    component: Trainings,
  },
  {
    path: uriHelper.new_employees_trainings_uri(),
    exact: true,
    modalComponent: TrainingCreateModal,
  },
  {
    path: uriHelper.edit_employees_trainings_uri(),
    exact: true,
    modalComponent: TrainingEditModal,
  },
  {
    path: uriHelper.show_employees_trainings_uri(),
    exact: true,
    modalComponent: TrainingShowModal,
  },
];

export const EmployeeContext = React.createContext({
  headerComponent: null as React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeHeaderComponent: (component: React.ReactNode) => {},
  managePhoto: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeManagePhoto: (managePhoto: boolean) => {},
});

export interface EmployeeDetailState {
  managePhoto: boolean;
  changeManagePhoto: (managePhoto: boolean) => void;
  headerComponent: React.ReactNode;
  changeHeaderComponent: (component: React.ReactNode) => void;
}

export default class EmployeeDetail extends BaseRouter<EmployeeDetailProps, EmployeeDetailState> {
  constructor(props: EmployeeDetailProps) {
    super(props);

    this.state = {
      managePhoto: false,
      changeManagePhoto: this.changeManagePhoto,
      headerComponent: null,
      changeHeaderComponent: this.changeHeaderComponent,
    };
  }

  changeManagePhoto = (managePhoto: boolean) => {
    this.setState(state => ({
      managePhoto,
    }));
  };

  changeHeaderComponent = (component: React.ReactNode) => {
    this.setState(state => ({
      headerComponent: component,
    }));
  };

  render() {
    const userId = this.props.match.params.user_id;

    const sidebarLinks = [];

    if (this.user.allowToPage(UserRightsPages.EVIDENCE_STATE)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_evidence_states(userId),
        title: "employee.evidence_state.list",
      });
    }

    if (this.user.allowToPage(UserRightsPages.CONTRACT)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_contracts(userId),
        title: "employee.contract.list",
      });
    }

    if (this.user.allowToPage(UserRightsPages.PERSONAL_DATA)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_personal_data(userId),
        title: "employee.personal_data.list",
      });
    }

    if (this.user.allowToPage(UserRightsPages.CONTACT_INFORMATION)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_contact_informations(userId),
        title: "employee.contact_information.list",
      });
    }

    if (this.user.allowToObject(UserRightsObjects.HEALTH_INSURANCE)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_health_insurances(userId),
        title: "employee.health_insurance.list",
      });
    }

    if (this.user.allowToObject(UserRightsObjects.DEPENDENT)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_dependents(userId),
        title: "employee.dependent.list",
      });
    }

    if (this.user.allowToObject(UserRightsObjects.NOTE)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_notes(userId),
        title: "employee.note.list",
      });
    }

    if (this.user.allowToObject(UserRightsObjects.TRAINING)) {
      sidebarLinks.push({
        path: this.uriHelper.employees_trainings(userId),
        title: "employee.training.list",
      });
    }

    return (
      <>
        <div className="emankit__sidebar">
          {this.sidebarLinks(
            sidebarLinks,
            this.user.allowToPage(UserRightsPages.EMPLOYEES) ? this.uriHelper.employees() : undefined
          )}
        </div>
        <div className="emankit__layout__content emankit__layout__content--hasSidebar">
          <Breadcrumbs />
          <EmployeeShow match={this.props.match} managePhoto={this.state.managePhoto}>
            {this.state.headerComponent}
          </EmployeeShow>
          <EmployeeContext.Provider value={this.state}>{this.pageRouteRender(employeeRoutes)}</EmployeeContext.Provider>
        </div>
      </>
    );
  }
}
