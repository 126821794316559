import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import EnumType from "@model/EnumType";
import {
  CheckBox,
  CheckBoxArray,
  DatePickerBox,
  GenericFormField,
  MultiSelectBox,
  SelectWithSearchBox,
  TextAreaBox,
  TextBox,
} from "@util/Form";
import Enum from "@service/Enum";
import { OptionType } from "@eman/emankit";

export interface EnumFormProps {
  enumerationContainerName: string;
  autoReplyOptions?: boolean;
  possibleUsers?: OptionType<number>[];
  possibleJobPositions?: OptionType<string>[];
  enums: Enum;
}

@observer
export default class EnumForm extends BaseForm<EnumType, EnumFormProps> {
  modelName = "enumeration";

  handleIsWorkChanged = (isWork: boolean) => {
    if (isWork === true) {
      this.props.entity.minimum_amount = 0;
    }
  };

  renderFormBody() {
    const { enumerationContainerName, autoReplyOptions } = this.props;

    // TODO: Tak tohle je šílený
    return (
      <Columns>
        {this.generateFormField("name", <TextBox />, true)}
        {this.generateFormField("code", <TextBox />, true)}
        {this.generateFormField("order", <TextBox type="number" />)}
        {enumerationContainerName === "social_security_offices" && this.generateFormField("district", <TextBox />)}
        {enumerationContainerName === "citizenships" && this.generateFormField("eurozone", <CheckBox />, true)}
        {["working_times", "rate_item_types"].indexOf(enumerationContainerName) !== -1 &&
          this.generateFormField("coeficient", <TextBox type="number" />, true)}
        {["supergrosses"].indexOf(enumerationContainerName) !== -1 &&
          this.generateFormField("value", <TextBox type="number" />, true)}
        {["supergrosses"].indexOf(enumerationContainerName) !== -1 &&
          this.generateFormField("valid_from", <DatePickerBox />, true)}
        {["supergrosses"].indexOf(enumerationContainerName) !== -1 &&
          this.generateFormField("valid_to", <DatePickerBox />, false, { disabled: true })}
        {enumerationContainerName === "contract_types" && this.generateFormField("supergross", <CheckBox />, true)}

        {enumerationContainerName === "contract_types" && (
          <GenericFormField
            target={this.props.entity}
            property={"allowed_user_types"}
            label={this.ta("allowed_user_types")}
            required={false}
            key={"allowed_user_types"}>
            {this.enums.values("user_types").map(user => (
              <CheckBoxArray id={user.code} key={user.code} ownLabel={user.name} />
            ))}
          </GenericFormField>
        )}

        {enumerationContainerName === "rate_item_types" && this.generateFormField("calculation", <CheckBox />, true)}
        {enumerationContainerName === "allocation_types" &&
          this.generateFormField("is_work", <CheckBox onValueChanged={this.handleIsWorkChanged} />, true)}
        {enumerationContainerName === "allocation_types" && this.generateFormField("allow_total", <CheckBox />, true)}
        {enumerationContainerName === "allocation_types" && this.generateFormField("mandatory_time", <CheckBox />, true)}
        {enumerationContainerName === "allocation_types" &&
          !this.props.entity.is_work &&
          this.generateFormField("minimum_amount", <TextBox type="number" disabledDecimal />, true)}
        {enumerationContainerName === "evidence_state_reasons" && (
          <>
            {this.generateFormField("before_status", <TextBox />)}
            {this.generateFormField("after_status", <TextBox />)}
          </>
        )}
        {enumerationContainerName === "allocation_types" && this.generateFormField("auto_reply", <CheckBox />, true)}
        {enumerationContainerName === "allocation_types" &&
          autoReplyOptions &&
          this.generateFormField("minimum_auto_reply_day", <TextBox type="number" disabledDecimal />, true)}
        {enumerationContainerName === "allocation_types" &&
          autoReplyOptions &&
          this.generateFormField("reply_subject", <TextBox />, true)}
        {enumerationContainerName === "allocation_types" &&
          autoReplyOptions &&
          this.generateFormField("reply_body", <TextAreaBox />, true)}
        {enumerationContainerName === "training_types" &&
          this.props.possibleUsers &&
          this.generateFormField(
            "supervisor_id",
            <SelectWithSearchBox options={this.props.possibleUsers} menuPosition={"fixed"} />,
            true
          )}
        {enumerationContainerName === "training_types" &&
          this.props.possibleJobPositions &&
          this.generateFormField(
            "job_positions",
            <MultiSelectBox<string> options={this.props.possibleJobPositions} menuPosition={"fixed"} />,
            true
          )}
        {enumerationContainerName === "training_types" && this.generateFormField("url", <TextBox />, false)}
      </Columns>
    );
  }
}
