import LocalizationService from "@service/Localization";
import { BalanceGroupWithDuration } from "@util/Calendar";
import AllocationTableItem from "../Item";

interface Props {
  balance: BalanceGroupWithDuration;
  isYear?: boolean;
  yearWidth?: number;
  offSetYear?: number;
  locs?: LocalizationService;
}

const BalanceRowItem = ({ balance, yearWidth, offSetYear, isYear, locs }: Props) => {
  let className = "";
  let text = "";
  const value_rounded = Math.round(balance.value * 100) / 100;

  if (balance.is_work) {
    if (balance.value === 0) {
      className = "full";
      text = `${locs ? locs.tg(`uikit.alocation_table.full`) : ""}`;
    }

    if (balance.zero_sum_value_ok === false) {
      className = "wrong-full";
    }
    if (balance.value > 0) {
      className = "free";
      text = `${value_rounded}h\n ${locs ? locs.tg(`uikit.alocation_table.free`) : ""}`;
    }

    if (balance.value < 0) {
      className = "over";
      text = `${Math.abs(value_rounded)}h\n ${locs ? locs.tg(`uikit.alocation_table.more`) : ""}`;
    }
  } else if (balance.is_work === false) {
    className = "off";
    text = "Off";
  } else if (balance.is_work === null && balance.value > 0) {
    className = "free";
    text = `${value_rounded}h\n ${locs ? locs.tg(`uikit.alocation_table.free`) : ""}`;
  } else {
    className = "disabled";
    text = "";
  }

  // Edge case overalocated
  if (balance.is_work === false && balance.value < 0) {
    className = "over";
    text = `${Math.abs(value_rounded)}h\n ${locs ? locs.tg(`uikit.alocation_table.more`) : ""}`;
  }

  return (
    <AllocationTableItem
      className={`balance-item ${className}`}
      style={isYear ? { width: `${yearWidth}%`, marginLeft: `${offSetYear}px` } : {}}>
      <span className="text">{text}</span>
    </AllocationTableItem>
  );
};

export default BalanceRowItem;
