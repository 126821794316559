import FormViewModel from "@vm/Form/FormViewModel";
import { inject, injectable } from "inversify";
import { action, computed, IReactionDisposer, reaction } from "mobx";

import BranchOffice from "@model/BranchOffice";
import Company from "@model/Company";
import Contract, { ContractType, ContractValidityType, UserType } from "@model/Contract";
import OrganizationUnit from "@model/OrganizationUnit";
import PaymentInfo from "@model/PaymentInfo";
import Enum from "@service/Enum";
import Localization from "@service/Localization";
import { setValue } from "@util/Form";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
import { OptionType } from "@eman/emankit";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import endOfMonth from "date-fns/endOfMonth";
import set from "date-fns/set";
import sub from "date-fns/sub";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ContractFormVM extends FormViewModel<Contract> implements ViewModel.WithReactions {
  private reactionDisposers: IReactionDisposer[] = [];

  private branchOfficeOptionsVM: OptionsVM<BranchOffice>;
  private companyOptionsVM: OptionsVM<Company>;
  private contractOptionsVM: OptionsVM<Contract>;
  private paymentInfoOptionsVM: OptionsVM<PaymentInfo>;

  constructor(
    @inject(TYPES.ContractRepository) private contractRepository: AssociatedRepository<Contract>,
    @inject(TYPES.CompanyRepository) companyRepository: Repository<Company>,
    @inject(TYPES.OrganizationUnitRepository) organizationUnitRepository: Repository<OrganizationUnit>,
    @inject(TYPES.PaymentInfoRepository) private paymentInfoRepository: AssociatedRepository<PaymentInfo>,
    @inject(TYPES.BranchOfficeRepository) private branchOfficeRepository: AssociatedRepository<BranchOffice>,
    @inject(TYPES.Enum) private enums: Enum,
    @inject(TYPES.Localization) private locs: Localization
  ) {
    super();
    this.contractOptionsVM = new OptionsVM(this.contractRepository, "valid_from", {
      order: { direction: "desc", field: "valid_from" },
      skipFetching: true,
    });

    this.companyOptionsVM = new OptionsVM(companyRepository, "name");

    this.paymentInfoOptionsVM = new OptionsVM(this.paymentInfoRepository, "account_number", { skipFetching: true });
    this.branchOfficeOptionsVM = new OptionsVM(this.branchOfficeRepository, "name", { skipFetching: true });
  }

  setEntity(entity: Contract) {
    // We need firstly turn reaction off to prevent it to change original entity data
    this.turnOffReactions();
    super.setEntity(entity);
    // When entity is set we want to react on its change
    this.turnOnReactions();
    if (entity.company_id) {
      this.initBranchOfficeOptions(entity.company_id);
    }
  }

  /**
   * React on Company selection and fetch branches of it
   */
  turnOnReactions(): void {
    this.reactionDisposers.push(
      reaction(
        () => this.entity.enumeration_termination_method_id,
        () => this.calculateTerminatedAt()
      ),
      reaction(
        () => this.entity.valid_to,
        () => this.calculateNewTermination()
      ),
      reaction(
        () => this.entity.enumeration_termination_method_id,
        () => this.calculateNewTermination()
      ),
      reaction(
        () => this.entity.terminated_at,
        () => this.calculateValidTo()
      ),
      reaction(
        () => this.entity.company_id,
        companyId => this.onCompanyChange(companyId)
      ),
      reaction(
        () => this.entity.enumeration_termination_method_id,
        () => this.calculateTerminatedAt()
      ),

      reaction(
        () => this.entity.enumeration_contract_validity_type_id,
        validityTypeId => this.onValidityTypeChange(validityTypeId)
      ),

      reaction(
        () => this.entity.enumeration_termination_method_id,
        terminationMethodId => this.onTerminationMethodChange(terminationMethodId)
      ),

      reaction(
        () => this.entity.valid_from,
        validFrom => this.onValidFromChange(validFrom)
      ),

      reaction(
        () => this.entity.valid_to,
        validTo => this.onValidToChange(validTo!)
      ),

      reaction(
        () => this.entity?.enumeration_contract_type_id,
        () => this.onContractTypeChange()
      ),

      reaction(
        () => this.entity?.enumeration_user_type_id,
        () => this.onUserTypeChange()
      ),

      reaction(
        () => this.entity.notice_period_months,
        () => this.onNoticePeriodMonthsChange()
      )
    );
  }

  turnOffReactions(): void {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  setParentId(parentId: number) {
    this.paymentInfoRepository.setId(parentId);
    this.paymentInfoOptionsVM.fetchItems();

    this.contractRepository.setId(parentId);
    this.contractOptionsVM.fetchItems();
  }

  onCompanyChange = (companyId: number) => {
    setValue(this.entity, "branch_office_id", undefined);
    this.initBranchOfficeOptions(companyId);
  };

  onValidityTypeChange = (validityTypeId: number) => {
    if (validityTypeId !== 1) {
      setValue(this.entity, "valid_to", undefined);
    }
  };

  initBranchOfficeOptions = (companyId: number) => {
    this.branchOfficeRepository.setId(companyId);
    this.branchOfficeOptionsVM.fetchItems();
  };

  onTerminationMethodChange = (enumerationTerminationMethodId: number) => {
    if (enumerationTerminationMethodId === null) {
      setValue(this.entity, "terminated_at", null);
      setValue(this.entity, "valid_to", null);
    }
  };

  onValidFromChange = (validFrom: Date) => {
    if ([ContractType.DPP, ContractType.ICO].indexOf(this.currentContractType?.code as ContractType) !== -1) {
      setValue(this.entity, "insurance_from", validFrom);
    } else {
      setValue(this.entity, "insurance_from", null);
    }
  };

  onValidToChange = (validTo: Date) => {
    if (
      [ContractType.DPP, ContractType.ICO as string].indexOf(this.currentContractType?.code as ContractType) !== -1 &&
      this.entity.contract_validity_type.code === ContractValidityType.DU &&
      validTo
    ) {
      setValue(this.entity, "insurance_to", validTo);
    } else {
      setValue(this.entity, "insurance_to", null);
    }
  };

  onContractTypeChange = () => {
    if ([ContractType.DPP, ContractType.ICO].indexOf(this.currentContractType?.code as ContractType) !== -1) {
      setValue(this.entity, "insurance_from", this.entity.valid_from);
      if (this.currentContractType?.code === ContractValidityType.DU) {
        setValue(this.entity, "insurance_to", this.entity.valid_to);
      }
    } else {
      setValue(this.entity, "insurance_from", null);
      setValue(this.entity, "insurance_to", null);
    }

    if (this.entity.enumeration_termination_method_id) {
      this.calculateTerminatedAt();
    }
  };

  onUserTypeChange = () => {
    if ([UserType.EXTERNAL].indexOf(this.currentUserType?.code as UserType) !== -1) {
      setValue(
        this.entity,
        "enumeration_contract_type_id",
        this.enums.values("contract_types").find(type => type.code === ContractType.ICO)?.id
      );
    }
  };

  @computed get currentContractType() {
    return this.enums.value("contract_types", this.entity?.enumeration_contract_type_id, false);
  }

  @computed get currentUserType() {
    return this.enums.value("user_types", this.entity?.enumeration_user_type_id, false);
  }

  get allowValidToSelection() {
    return !!this.entity?.notice_period_months;
  }

  @computed
  get branchOfficeOptions() {
    return this.branchOfficeOptionsVM.selectOptions;
  }

  @computed
  get paymentInfoOptions(): OptionType<number>[] {
    return this.paymentInfoOptionsVM.items.map(item => {
      this.enums.assignObjectEnum(item);
      let name = `${item.account_number}/${item.bank_code?.code}`;
      if (item.account_number_prefix) {
        name = `${item.account_number_prefix}-${name}`;
      }

      return {
        label: name,
        value: item.id!,
      };
    });
  }

  @computed
  get companyOptions() {
    return this.companyOptionsVM.selectOptions;
  }

  @computed
  get contractOptions(): OptionType<number>[] {
    let filterFunction = (contract: Contract) => true;
    if (this.entity && this.entity.id) {
      filterFunction = (contract: Contract) => contract.id !== this.entity.id;
    }

    return this.contractOptionsVM.items.filter(filterFunction).map(contract => {
      this.enums.assignObjectEnum(contract);
      const name = `${contract.contract_type?.name} - ${this.locs.formatDate(contract.valid_from)}`;

      return {
        label: name,
        value: contract.id!,
      };
    });
  }

  @computed
  get contractTypesOptions() {
    return this.enums
      .values("contract_types")
      .filter(contract => {
        return (
          contract.allowed_user_types && +contract.allowed_user_types.includes(this.entity?.enumeration_user_type_id?.toString())
        );
      })
      .map(contract => {
        return {
          value: contract.id,
          label: contract.name,
        };
      });
  }

  get validFromMinimumDate(): Date {
    return sub(new Date(), { days: 8 });
  }

  get validFromToday(): Date {
    return new Date();
  }

  get validFromWorkStart(): Date {
    return this.entity.valid_from;
  }

  @computed
  get terminatedAtMaximumDate(): Date | undefined {
    if (this.entity?.enumeration_contract_validity_type_id == 1) {
      return this.entity.end_at;
    }
    return undefined;
  }

  @computed
  get validToTerminationMaximumDate(): Date | undefined {
    return this.entity?.end_at;
  }

  @computed
  get validToTerminationMinimumDate(): Date | undefined | null {
    return this.entity?.terminated_at;
  }

  onNoticePeriodMonthsChange() {
    if (this.entity.enumeration_termination_method_id) {
      this.calculateTerminatedAt();
    }
  }

  @action.bound
  calculateTerminatedAt() {
    const TODAY = new Date();

    if (ContractType.HPP === this.currentContractType?.code) {
      this.entity.terminated_at = set(TODAY, { month: TODAY.getMonth() + 1, date: 1 });
    }

    if (
      [ContractType.ICO, ContractType.DPP, ContractType.SP, ContractType.DPC].indexOf(
        this.currentContractType?.code as ContractType
      ) !== -1
    ) {
      this.entity.terminated_at = TODAY;
    }
  }

  @action.bound
  calculateNewTermination() {
    if (!this.entity.enumeration_termination_method_id || !this.entity.valid_to) {
      // Need set entity as null for BE
      this.entity.terminated_at = null;
    }
  }

  @action.bound
  calculateValidTo() {
    if (
      this.entity.terminated_at &&
      [ContractType.ICO, ContractType.DPP, ContractType.SP, ContractType.DPC].indexOf(
        this.currentContractType?.code as ContractType
      ) !== -1
    ) {
      this.entity.valid_to = addDays(
        addMonths(this.entity?.terminated_at || new Date(), this.entity.notice_period_months ?? 0),
        this.entity.notice_period_months === 0 || this.entity.notice_period_months === null ? 0 : -1
      );
    }

    if (this.entity.terminated_at && ContractType.HPP === this.currentContractType?.code) {
      this.entity.valid_to = endOfMonth(addMonths(this.entity.terminated_at, this.entity.notice_period_months ?? 0));
    }
  }
}
