import { action, observable } from "mobx";

import BaseModel from "@model/BaseModel";
import ListViewModel from "@vm/List/ListViewModel";

export default class AssociatedListViewModel<TModel extends BaseModel, TRepository extends AssociatedRepository<TModel>>
  extends ListViewModel<TModel, TRepository>
  implements ViewModel.Associated {
  @observable parentId: number;

  async init(parentId?: number) {
    if (this.parentId === undefined && !parentId) {
      throw new Error("You have to set parentId first to use this associated VM");
    }
    if (parentId) {
      this.setParentId(parentId);
    }
    return super.init();
  }

  @action
  setParentId(parentId: number, skipFetching = true) {
    if (this.parentId !== parentId) {
      debugger;
      this.parentId = parentId;
      this.repository.setId(parentId);

      if (!skipFetching) {
        this.fetchList();
      }
    }
  }
}
