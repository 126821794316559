import BaseComponent from "@component/BaseComponent";
import JobPosition from "@model/JobPosition";
import { noop } from "lodash";

interface JobTitleSidebarProps {
  jobPosition?: JobPosition;
  onClick?: (...params: any) => any;
  organizationName?: string;
}

export default class JobTitleSidebar extends BaseComponent<JobTitleSidebarProps> {
  render() {
    let sidebarRenderer: string | undefined;
    const { jobPosition, onClick, organizationName } = this.props;

    if (jobPosition) {
      sidebarRenderer = `${organizationName || ""} ${organizationName ? "-" : ""} ${jobPosition.name}`;
    } else {
      sidebarRenderer = "Nepřítomnost";
    }

    return (
      <div className="job-sidebar" onClick={onClick || noop}>
        <a className="name width-limit">{sidebarRenderer}</a>
      </div>
    );
  }
}
