import EmployeeAllocationsVM, { EmployeeFilterItem } from "@vm/Other/EmployeeAllocations";
import { observer } from "mobx-react";
import React from "react";
import { lazyInject } from "src/inversify.config";
import TYPES from "src/inversify.types";

import ResourceComponent from "@component/ResourceComponent";
import EmployeeFilter from "./employeeFilter";
import EmployeeSort from "./employeeSort";
import AllocationTable from "@component/AllocationTable";
import { AllocationInterval } from "@util/Calendar";
import AllocationCalendarNavigation from "@component/AllocationCalendarNavigation";
import { JobTitleState } from "@model/JobTitle";
import { EvidenceStateStatus } from "@model/EvidenceState";
import Pagination from "@eman/emankit/Pagination";
import EventBus, { SCROLL_TOP } from "@util/EventBus";

@observer
export default class EmployeeCalendar<TProps = {}, TState = {}> extends ResourceComponent<TProps, TState> {
  prefix = "allocation";
  modelName = "calendar";

  @lazyInject(TYPES.EmployeeAllocations)
  vm: EmployeeAllocationsVM;

  componentDidMount(): void {
    this.vm.init();
  }

  componentWillUnmount() {
    this.vm.dispose();
  }

  onDatesChange = (startDate: Date, endDate: Date, interval: AllocationInterval) => {
    this.vm.startDate = startDate;
    this.vm.endDate = endDate;
    this.vm.interval = interval;
  };

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    this.vm.clearGroups();
    let updatedFilters = values;

    // Keep JobTitleState.ACTIVE and EvidenceStatus filter when filters are reseted
    if (Object.values(values).length === 0) {
      updatedFilters = {
        job_title_state: {
          operator: "in",
          values: [JobTitleState.ACTIVE],
        },
        evidence_state_evidence_status: {
          operator: "in",
          values: [EvidenceStateStatus.ACTIVED],
        },
      };
    }

    this.vm.setFilters(updatedFilters, visible);
  };

  onOrderSettingChange = (orderOptions: OrderOptions) => {
    if (orderOptions.field === "balance_sum") {
      this.vm.addBalanceSumFilter();
    } else {
      this.vm.cleanBalanceSumFilter();
    }
    this.vm.order = orderOptions;
  };

  onPageChange = (page: number, pageSize: number) => {
    this.vm.setPageAndPageSize(page, pageSize);
    EventBus.trigger(SCROLL_TOP);
  };

  render() {
    return (
      <div>
        <EmployeeFilter
          enums={this.enums}
          projectOptions={
            this.vm.settings?.visibleFilters?.includes(EmployeeFilterItem.PROJECT) ? this.vm.projectOptions : undefined
          }
          jobPositionsOptions={this.vm.getJobPositionsOptions}
          organizationsOptions={this.vm.getOrganizationsOptions}
          organizationUnitOptions={
            this.vm.settings?.visibleFilters?.includes(EmployeeFilterItem.ORGANIZATION_UNIT)
              ? this.vm.organizationUnitOptions
              : undefined
          }
          jobPositionOptions={
            this.vm.settings?.visibleFilters?.includes(EmployeeFilterItem.JOB_POSITION) ? this.vm.jobPositionOptions : undefined
          }
          userOptions={this.vm.settings?.visibleFilters?.includes(EmployeeFilterItem.USER) ? this.vm.userOptions : undefined}
          selectedItems={this.vm.settings?.filters}
          visibleFilters={this.vm.settings?.visibleFilters}
          onChange={this.onFilterChange}
          locs={this.locs}
          onResetView={this.vm.onResetView}
        />

        <AllocationTable
          startDate={this.vm.startDate}
          endDate={this.vm.endDate}
          leftHeader={<EmployeeSort locs={this.locs} onChange={this.onOrderSettingChange} />}
          data={this.vm.groups}
          subgroupsData={this.vm.subgroupsData}
          onGroupClick={this.vm.toggleGroup}
          onSubGroupClick={this.vm.toggleSubGroup}
          openSubGroups={this.vm.openSubGroups}
          onAllocationClick={this.vm.editAllocation}
          locs={this.locs}
          allocationInterval={this.vm.interval}
        />
        <AllocationCalendarNavigation
          onChange={this.onDatesChange}
          defaultStartDate={this.vm.startDate}
          defaultInterval={this.vm.interval}
          locs={this.locs}
          clearGroups={this.vm.clearGroups}
          tooltip={this.locs.tg("uikit.date_picker.strings.todayButton")}
        />
        <Pagination
          totalRecords={this.vm.total}
          pageSize={this.vm.pagination.pageSize}
          page={this.vm.pagination.page}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  }
}
