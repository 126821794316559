import { Exclude, Expose, Transform, Type } from "@eman/class-transformer";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import BranchOffice from "@model/BranchOffice";
import Company from "@model/Company";
import JobTitle from "@model/JobTitle";
import Rate from "@model/Rate";

export enum ContractState {
  CREATED = "created",
  ACTIVE = "active",
  DISMISSAL = "dismissal",
  CLOSED = "closed",
}

export enum ContractType {
  DPP = "DPP",
  ICO = "ICO",
  SP = "SP",
  HPP = "HPP",
  DPC = "DPC",
}

export enum UserType {
  INTERNAL = "1",
  EXTERNAL = "2",
  BODYSHOP = "5",
}

export enum ContractValidityType {
  DN = "DN",
  DU = "DU",
}

export default class Contract extends BaseModel {
  @observable company_id: number;
  @observable contract_id: number;
  @Expose({ name: "unit" })
  @observable
  unit_id: number;

  @observable branch_office_id: number;
  @observable state: ContractState;
  @observable payment_info_id: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  signed_at: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to?: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  end_at?: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  working_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  insurance_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  insurance_to: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  probation_period_to: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  terminated_at?: Date | null;

  @observable primary: boolean;
  @observable ignore_for_allocation: boolean;
  @observable notice_period_months: number;
  @observable termination_description: string;

  @Type(() => Number)
  @observable
  termination_severance: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Company)
  @observable
  company: Company;

  @Exclude({ toPlainOnly: true })
  @Type(() => BranchOffice)
  @observable
  branch_office: BranchOffice;

  @Exclude({ toPlainOnly: true })
  @Type(() => JobTitle)
  @observable
  job_titles: JobTitle[] = [];

  @observable enumeration_contract_type_id: number;
  @observable enumeration_contract_validity_type_id: number;
  @observable enumeration_termination_method_id: number;
  @observable enumeration_currency_id = 1;
  @observable enumeration_payment_method_id = 2;
  @observable enumeration_user_type_id = 1;

  @observable user_id: number;

  @enumValue("contract_types", "enumeration_contract_type_id")
  @observable
  contract_type: models.IEnumType;

  @enumValue("contract_validity_types", "enumeration_contract_validity_type_id")
  @observable
  contract_validity_type: models.IEnumType;

  @enumValue("termination_methods", "enumeration_termination_method_id")
  @observable
  termination_method: models.IEnumType;

  @enumValue("currencies", "enumeration_currency_id")
  @observable
  currency: models.IEnumType;

  @enumValue("payment_methods", "enumeration_payment_method_id")
  @observable
  payment_method: models.IEnumType;

  @enumValue("user_types", "enumeration_user_type_id")
  @observable
  contractor: models.IEnumType;

  @Exclude({ toPlainOnly: true })
  @Type(() => Rate)
  @observable
  rates?: Rate[];
}
