import { ReactNode } from "react";
import { BalanceGroupWithDuration } from "@util/Calendar";
import { Dictionary } from "lodash";
import BalanceRowItem from "./item";
import isBefore from "date-fns/isBefore";
import endOfMonth from "date-fns/esm/endOfMonth";
import getDaysInMonth from "date-fns/esm/getDaysInMonth";
import startOfMonth from "date-fns/esm/startOfMonth";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import LocalizationService from "@service/Localization";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

interface Props {
  interval: Date[];
  balances: Dictionary<BalanceGroupWithDuration>;
  sidebarRenderer: () => ReactNode;
  className?: string;
  totalFlow?: boolean;
  hideSidebar?: boolean;
  doubleHeight?: boolean;
  isYearInterval?: boolean;
  locs?: LocalizationService;
}

const BalanceRow = ({
  interval,
  balances,
  sidebarRenderer,
  className,
  totalFlow,
  hideSidebar,
  doubleHeight,
  isYearInterval,
  locs,
}: Props) => {
  const cells = [];

  let skip = 0;

  const balancesArray = Object.values(balances);

  // We need to convert date to number for sorting purpose, after sorting it will be available as Date again
  const sortedArray = balancesArray.sort(function (a, b) {
    return a.valid_from.getTime() - b.valid_from.getTime();
  });

  const checkMonthStartPick = sortedArray[0] && sortedArray[0]?.valid_from.getMonth() === sortedArray[0]?.valid_to.getMonth();

  // toDateString method for picking up right value from agregated object of lodash
  const dateStartCell = sortedArray[checkMonthStartPick ? 0 : 1]?.valid_from.toDateString();

  // Last item of array for calculating
  const lastItemOfArray = sortedArray.slice(-1)[0];

  const recalculatedBalancesValueEachDay = balancesArray.map(balance => ({
    value: balance.value,
    days: eachDayOfInterval({
      // Normalizing date values with new Date(date.toDateString()), because of different timezones after classTransformation
      start: new Date(balance.valid_from.toDateString()),
      end: new Date(balance.valid_to.toDateString()),
    }),
  }));

  const sumOfAllHours = recalculatedBalancesValueEachDay.reduce((prev, current) => prev + current.value * current.days.length, 0);

  // If every sub capacity is is_work: false, change totalBalance is_work too, (from business side it can be edited later)
  const isWorkFromAllBalances = sortedArray.every(balance => balance.is_work === false);

  // If some sub capacity is zero_sum_value_ok: false, change totalBalance zero_sum_value_ok too, (from business side it can be edited later)
  const zeroSumValueFromAllBalances = sortedArray.every(balance => balance.zero_sum_value_ok === true);

  const fullBalanceDuration = balancesArray.reduce((prev, current) => prev + current.duration, 0);

  const fullBalance = { value: sumOfAllHours, is_work: !isWorkFromAllBalances, zero_sum_value_ok: zeroSumValueFromAllBalances };

  // Variables for year flow
  let monthStartCell;

  let monthFullDuration;

  let endMonth;

  let monthsSpanWidthRatio;

  let todayPointerOffset;

  let startMonth;

  // Year flow
  if (isYearInterval) {
    const startDate = new Date(dateStartCell);

    monthStartCell = startOfMonth(startDate).toDateString();

    startMonth = startDate.getMonth();

    endMonth = lastItemOfArray?.valid_to.getMonth() || 11;

    monthFullDuration = endMonth + 1 - startMonth;

    if (sortedArray[0]) {
      // Calculating partial width in cell from first and last Date
      const spanDays = differenceInCalendarDays(sortedArray[0]?.valid_from, lastItemOfArray?.valid_to);

      const spanDaysFull = differenceInCalendarDays(
        startOfMonth(sortedArray[0]?.valid_from),
        endOfMonth(lastItemOfArray?.valid_to)
      );

      monthsSpanWidthRatio = spanDaysFull && spanDays && spanDays / spanDaysFull;

      const tdWidth = document.getElementById("get-width-td")?.getBoundingClientRect().width;
      // Calculating partial offset for margin from first and last Date
      todayPointerOffset =
        tdWidth && +((tdWidth * sortedArray[0]?.valid_from.getUTCDate()) / getDaysInMonth(sortedArray[0]?.valid_from)) - 5;
    }
  }

  // fullBalances flow
  if (totalFlow) {
    for (const item of interval) {
      // Skip N-Rows
      if (skip > 0) {
        skip--;
        continue;
      }
      const date = item.toDateString();

      // If there is no match for startDate of totalFlow, use indexType 0
      let balance;
      if (isYearInterval) {
        // Year flow
        balance = balances[monthStartCell === date ? dateStartCell : 0];
      } else {
        // Month and Week flow
        balance = balances[dateStartCell === date ? dateStartCell : 0];
      }

      if (balance?.duration > 0) {
        skip = balance.duration - 1;
        cells.push(
          <td
            key={date}
            colSpan={isYearInterval ? monthFullDuration : fullBalanceDuration}
            className={`table-col ${doubleHeight ? "double-height" : ""}`}
            data-value={balance.value}>
            <BalanceRowItem
              balance={fullBalance as BalanceGroupWithDuration}
              offSetYear={todayPointerOffset}
              yearWidth={monthsSpanWidthRatio && +monthsSpanWidthRatio?.toFixed(2) * 100}
              isYear={isYearInterval}
              locs={locs}
            />
          </td>
        );
      }

      if (
        // Checking for pushing in month, week VS year
        isBefore(item, new Date(isYearInterval ? monthStartCell || new Date() : dateStartCell || new Date())) ||
        balancesArray.length === 0 ||
        (endMonth && isYearInterval && startMonth && startMonth < 11 - endMonth)
      ) {
        cells.push(<td key={date} id="get-width-td" className="table-col"></td>);
      }
    }
  } else {
    // standardBalances flow
    for (const item of interval) {
      // Skip N-Rows
      if (skip > 0) {
        skip--;
        continue;
      }

      const date = item.toDateString();

      const balance = balances[date];

      if (balance?.duration > 0) {
        skip = balance.duration - 1;
        cells.push(
          <td key={date} colSpan={balance.duration} className="table-col" data-value={balance.value}>
            <BalanceRowItem balance={balance} locs={locs} />
          </td>
        );
      } else {
        cells.push(<td key={date} className="table-col"></td>);
      }
    }
  }

  // In some cases on Year interval, balances ends sooner then end of the year, and more free cells is pushed,
  // If we have more then 13 cells in year flow, Pop()
  isYearInterval && cells.length > 12 && cells.pop();

  return (
    <tr className={`${className} table-row`}>
      <td className="sidebar table-col">{!hideSidebar && sidebarRenderer()}</td>
      {cells}
    </tr>
  );
};

export default BalanceRow;
