/* eslint-disable @typescript-eslint/naming-convention */
const TYPES = {
  // Services
  Avatar: Symbol("Avatar"),
  Breadcrumb: Symbol("Breadcrumbs"),
  Enum: Symbol("Enum"),
  Notification: Symbol("Notification"),
  User: Symbol("CurrentUser"),
  Localization: Symbol("Localization"),
  Router: Symbol("Router"),
  Websocket: Symbol("Websocket"),

  // URI Helpers
  UriHelper: Symbol("UriHelper"),

  // Repositories
  AddressRepository: Symbol("AddressRepository"),
  AllocationRepository: Symbol("AllocationRepository"),
  AllocationItemRepository: Symbol("AllocationItemRepository"),
  AllocationJobTitleRepository: Symbol("AllocationJobTitleRepository"),
  AllocationItemGroupRepository: Symbol("AllocationItemGroupRepository"),
  AllocationUserRepository: Symbol("AllocationUserRepository"),
  AnnouncementRepository: Symbol("AnnouncementRepository"),
  BalanceRepository: Symbol("BalanceRepository"),
  BalanceGroupRepository: Symbol("BalanceGroupRepository"),
  BranchOfficeRepository: Symbol("BranchOfficeRepository"),
  CapacityRepository: Symbol("CapacityRepository"),
  CompanyRepository: Symbol("CompanyRepository"),
  ContactRepository: Symbol("ContactRepository"),
  ContractRepository: Symbol("ContractRepository"),
  CurrentUserRepository: Symbol("CurrentUserRepository"),
  DependentRepository: Symbol("DependentRepository"),
  EmployeeRepository: Symbol("EmployeeRepository"),
  EnumRepository: Symbol("EnumRepository"),
  EvidenceStateRepository: Symbol("EvidenceStateRepository"),
  ExportRepository: Symbol("ExportRepository"),
  HealthInsuranceRepository: Symbol("HealthInsuranceRepository"),
  NotificationRepository: Symbol("NotificationRepository"),
  JobPositionRepository: Symbol("JobPositionRepository"),
  JobTitlesRepository: Symbol("JobTitlesRepository"),
  JobTitleRepository: Symbol("JobTitleRepository"),
  OrganizationUnitRepository: Symbol("OrganizationUnitRepository"),
  OrganizationStructureRepository: Symbol("OrganizationUnitRepository"),
  PaymentInfoRepository: Symbol("PaymentInfoRepository"),
  PersonalDocumentRepository: Symbol("PersonalDocumentRepository"),
  PreviousEmploymentRepository: Symbol("PreviousEmploymentRepository"),
  ProjectRepository: Symbol("ProjectRepository"),
  RateRepository: Symbol("RateRepository"),
  RateItemRepository: Symbol("RateItemRepository"),
  RoleRepository: Symbol("RoleRepository"),
  SchoolRepository: Symbol("SchoolRepository"),
  UserRepository: Symbol("UserRepository"),
  NoteRepository: Symbol("NoteRepository"),
  TrainingRepository: Symbol("TrainingRepository"),
  UserTimeRatioRepository: Symbol("UserTimeRatioRepository"),
  WorkingTimeRatioRepository: Symbol("WorkingTimeRatioRepository"),

  // List VMs
  AddressList: Symbol("AddressListVM"),
  AnnouncementList: Symbol("AnnouncementListVM"),
  AllocationList: Symbol("AllocationListVM"),
  AllocationItemList: Symbol("AllocationItemListVM"),
  AllocationItemGroupList: Symbol("AllocationItemGroupListVM"),
  BalanceList: Symbol("BalanceListVM"),
  BalanceGroupList: Symbol("BalanceGroupListVM"),
  BranchOfficeList: Symbol("BranchOfficeListVM"),
  CapacityList: Symbol("CapacityListVM"),
  ContactList: Symbol("ContactListVM"),
  ContractList: Symbol("ContractListVM"),
  DependentList: Symbol("DependentListVM"),
  EmployeeList: Symbol("EmployeeListVM"),
  EnumList: Symbol("EnumListVM"),
  EnumContainerList: Symbol("EnumContainerListVM"),
  EvidenceStateList: Symbol("EvidenceStateListVM"),
  ExportList: Symbol("ExportListVM"),
  HealthInsuranceList: Symbol("HealthInsuranceListVM"),
  JobPositionList: Symbol("JobPositionListVM"),
  JobTitleList: Symbol("JobTitleListVM"),
  JobTitlesCheck: Symbol("JobTitlesCheckVM"),
  OrganizationStructureList: Symbol("OrganizationStructureListVM"),
  PaymentInfoList: Symbol("PaymentInfoListVM"),
  PersonalDocumentList: Symbol("PersonalDocumentListVM"),
  PreviousEmploymentList: Symbol("PreviousEmploymentListVM"),
  ProjectList: Symbol("ProjectListVM"),
  RateList: Symbol("RateListVM"),
  RoleList: Symbol("RoleListVM"),
  SchoolList: Symbol("SchoolListVM"),
  UserList: Symbol("UserListVM"),
  NoteList: Symbol("NoteListVM"),
  TrainingList: Symbol("TrainingListVM"),
  WorkingTimeRatiosList: Symbol("WorkingTimeRatiosList"),

  // Items VMs
  AllocationUserItems: Symbol("AllocationUserListVM"),

  // Show VMs
  AddressShow: Symbol("AddressShowVM"),
  AnnouncementShow: Symbol("AnnouncementShowVM"),
  BranchOfficeShow: Symbol("BranchOfficeShowVM"),
  CapacityShow: Symbol("CapacityShowVM"),
  ContactShow: Symbol("ContactShowVM"),
  DependentShow: Symbol("DependentShowVM"),
  EnumContainerShow: Symbol("EnumerationContainerShowVM"),
  EvidenceStateShow: Symbol("EvidenceStateShowVM"),
  HealthInsuranceShow: Symbol("HealthInsuranceShowVM"),
  JobPositionShow: Symbol("JobPositionShowVM"),
  JobTitleShow: Symbol("JobTitleShowVM"),
  OrganizationStructureShow: Symbol("OrganizationStrctureShowVM"),
  PaymentInfoShow: Symbol("PaymentInfoShowVM"),
  PersonalDocumentShow: Symbol("PersonalDocumentShowVM"),
  PreviousEmploymentShow: Symbol("PreviousEmploymentShowVM"),
  RateShow: Symbol("RateShowVM"),
  SchoolShow: Symbol("SchoolShowVM"),
  UserShow: Symbol("UserShowVM"),
  NoteShow: Symbol("NoteShowVM"),
  TrainingShow: Symbol("TrainingShowVM"),

  // Create VMs
  AddressCreate: Symbol("AddressCreateVM"),
  AllocationCreate: Symbol("AllocationCreateVM"),
  AnnouncementCreate: Symbol("AnnouncementCreateVM"),
  BranchOfficeCreate: Symbol("BranchOfficeCreateVM"),
  CapacityCreate: Symbol("CapacityCreateVM"),
  CompanyCreate: Symbol("CompanyCreateVM"),
  ContactCreate: Symbol("ContactCreateVM"),
  ContractCreate: Symbol("ContractCreateVM"),
  DependentCreate: Symbol("DependentCreateVM"),
  EnumCreate: Symbol("EnumCreateVM"),
  ExportCreate: Symbol("ExportCreateVM"),
  EvidenceStateCreate: Symbol("EvidenceStateCreateVM"),
  HealthInsuranceCreate: Symbol("HealthInsuranceCreateVM"),
  JobPositionCreate: Symbol("JobPositionCreateVM"),
  JobTitleCreate: Symbol("JobTitleCreateVM"),
  OrganizationStructureCreate: Symbol("OrganizationStructureCreateVM"),
  OrganizationUnitCreate: Symbol("OrganizationUnitCreateVM"),
  PaymentInfoCreate: Symbol("PaymentInfoCreateVM"),
  PersonalDocumentCreate: Symbol("PersonDocumentCreateVM"),
  PreviousEmploymentCreate: Symbol("PreviousEmploymentCreateVM"),
  RateCreate: Symbol("RateCreateVM"),
  RoleCreate: Symbol("RoleCreateVM"),
  SchoolCreate: Symbol("SchoolCreateVM"),
  UserCreate: Symbol("UserCreateVM"),
  NoteCreate: Symbol("NoteCreateVM"),
  TrainingCreate: Symbol("TrainingCreateVM"),

  // Edit VMs
  AddressEdit: Symbol("AddressEditVM"),
  AllocationEdit: Symbol("AllocationEditVM"),
  BranchOfficeEdit: Symbol("BranchOfficeEditVM"),
  CapacityEdit: Symbol("CapacityEditVM"),
  ContactEdit: Symbol("ContactEditVM"),
  ContractEdit: Symbol("ContractEditVM"),
  DependentEdit: Symbol("DependentEditVM"),
  EnumEdit: Symbol("EnumEditVM"),
  EvidenceStateEdit: Symbol("EvidenceStateEditVM"),
  HealthInsuranceEdit: Symbol("HealthInsuranceEditVM"),
  JobPositionEdit: Symbol("JobPositionEditVM"),
  JobTitleEdit: Symbol("JobTitleEditVM"),
  OrganizationStructureEdit: Symbol("OrganizationStructureEditVM"),
  PaymentInfoEdit: Symbol("PaymentInfoEditVM"),
  PersonalDocumentEdit: Symbol("PersonalDocumentEditVM"),
  PreviousEmploymentEdit: Symbol("PreviousEmploymentEditVM"),
  RateEdit: Symbol("RateEditVM"),
  RoleEdit: Symbol("RoleEditVM"),
  SchoolEdit: Symbol("SchoolEditVM"),
  UserEdit: Symbol("UserEditVM"),
  NoteEdit: Symbol("NoteEditVM"),
  TrainingEdit: Symbol("TrainingEditVM"),
  WorkingTimeRatiosEdit: Symbol("WorkingTimeRatiosEdit"),

  // Edit List VM
  RateItemEditList: Symbol("RateItemEditListVM"),

  // Form VM
  AllocationForm: Symbol("AllocationFormVM"),
  CapacityForm: Symbol("CapacityFormVM"),
  ContractForm: Symbol("ContractFormVM"),
  JobTitleForm: Symbol("JobTitleFormVM"),
  RoleForm: Symbol("RoleFormVM"),
  PreviousEmploymentForm: Symbol("PreviousEmploymentForm"),
  PersonalDocumentForm: Symbol("PersonalDocumentForm"),
  SchoolForm: Symbol("SchoolFormVM"),
  ExportForm: Symbol("ExportFormVM"),

  // Other
  Attachments: Symbol("AttachmentsVM"),
  Contracts: Symbol("ContractsVM"),
  MyAllocations: Symbol("MyAllocationsVM"),
  UserAllocations: Symbol("UserAllocationsVM"),
  EmployeeAllocations: Symbol("EmployeeAllocationsVM"),
};

export default TYPES;
