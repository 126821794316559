import differenceInDays from "date-fns/differenceInDays";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { computed, IReactionDisposer, reaction, action, observable } from "mobx";
import { inject, injectable } from "inversify";

import Allocation from "@model/Allocation";
import AllocationUser from "@model/AllocationUser";
import Project from "@model/Project";
import WeekAmount from "@model/WeekAmount";
import FormViewModel from "@vm/Form/FormViewModel";
import OptionsVM from "@vm/Other/Options";
import { setValue } from "@util/Form";
import CurrentUser from "@service/CurrentUser";
import Enum from "@service/Enum";

import TYPES from "../../inversify.types";
import { UserState, UserStateByContract } from "@model/User";
import GroupedOptionsVM from "@vm/Other/GroupedOptions";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import AllocationValidFromTime from "@model/AllocationValidFromTime";
import CapacityRepository from "@repository/Capacity";
import UserTimeRatioRepository from "@repository/UserTimeRatioRepository";
import Capacity from "@model/Capacity";
import BaseModel, { CUSTOM_DATE_FORMAT } from "@model/BaseModel";
import Localization from "@service/Localization";
import { OptionType } from "@eman/emankit";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import ViewHelpers, { JobOptionType } from "@util/ViewHelpers";
import { startOfDay } from "date-fns";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

export interface AllowedDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface Days {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export interface PossibleDay {
  name: string;
  date?: Date;
  capacity: number;
}

@injectable()
export default class AllocationFormVM extends FormViewModel<Allocation> implements ViewModel.WithReactions {
  private allDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  @observable
  private allocationUserOptionsVM: OptionsVM<AllocationUser>;
  @observable
  private allocationUserHelperOptionsVM: OptionsVM<AllocationUser>;
  @observable
  private projectOptionsVM: GroupedOptionsVM<Project>;
  @observable
  private capacityOptionsVM: OptionsVM<Capacity>;
  @observable
  private userTimeRatioOptionsVM: OptionsVM<WorkingTimeRatio>;

  private reactionDisposers: IReactionDisposer[] = [];

  private weekAmountReactionDisposer?: IReactionDisposer;

  /* Helper methods for details */
  idToProject: { [key: number]: Project } = {};
  idToUser: { [key: number]: AllocationUser } = {};
  due_date_advance: boolean;
  @observable hide_empty_capacities: boolean;

  constructor(
    @inject(TYPES.Enum) private enums: Enum,
    @inject(TYPES.User) private currentUser: CurrentUser,
    @inject(TYPES.AllocationUserRepository) private allocationUserRepository: Repository<AllocationUser>,
    @inject(TYPES.ProjectRepository) private projectRepository: Repository<Project>,
    @inject(TYPES.CapacityRepository) private capacityRepository: CapacityRepository,
    @inject(TYPES.Localization) private locs: Localization,
    @inject(TYPES.UserTimeRatioRepository) private userTimeRatioRepository: UserTimeRatioRepository
  ) {
    super();
  }

  async setEntity(entity: Allocation) {
    // We first need to turn reactions off to prevent it from changing original entity data
    this.turnOffReactions();

    this.projectOptionsVM = new GroupedOptionsVM(this.projectRepository, "name", { skipFetching: true });

    this.allocationUserOptionsVM = new OptionsVM(this.allocationUserRepository, "user_last_name" as any, {
      skipFetching: false,
      filters: {
        contract_state: {
          operator: "in",
          values: [
            UserStateByContract.ACTIVE,
            UserStateByContract.CREATED,
            UserStateByContract.DISMISSAL,
            UserStateByContract.PROBATION,
          ],
        },
        user_state: {
          operator: "in",
          values: [UserState.ACTIVE, UserState.CREATED, UserState.PROBATION, UserState.DISMISSAL],
        },
      },
    });

    // This is helper to deal with missing "OR" operator at filters
    this.allocationUserHelperOptionsVM = new OptionsVM(this.allocationUserRepository, "user_last_name" as any, {
      skipFetching: false,
      filters: {
        contract_state: {
          operator: "in",
          values: [
            UserStateByContract.ACTIVE,
            UserStateByContract.CREATED,
            UserStateByContract.DISMISSAL,
            UserStateByContract.PROBATION,
          ],
        },
      },
    });

    // Init editation
    if (entity.id) {
      await this.allocationUserHelperOptionsVM.fetchItems({ user_id: { operator: "in", values: entity.user_id } });
      this.onUserIdChange(entity.user_id);
    }

    await Promise.all([this.projectOptionsVM.fetchItems()]);

    this.projectOptionsVM.items.forEach(item => (this.idToProject[item.id!] = item));
    this.allocationUserOptionsVM.items.forEach(item => (this.idToUser[item.id!] = item));

    if (entity && entity.project_id) {
      // Set proper group
      entity.project_parent_id = this.projectOptionsVM.items?.find(x => x.id === entity.project_id)?.meta?.group?.id;
    }

    super.setEntity(entity);

    // Preselect user if user selection is disabled
    if (!this.allowUserSelection) {
      this.entity.user_id = this.currentUser.entity.id!;
      this.onUserIdChange(this.entity.user_id);
    }

    // When entity is set we want to react on its change
    this.turnOnReactions();
  }

  turnOnReactions(): void {
    this.reactionDisposers.push(
      reaction(
        () => this.entity.enumeration_allocation_type_id,
        allocationType => this.onAllocationTypeChange(allocationType)
      ),
      reaction(
        () => this.entity.user_id,
        userId => this.onUserIdChange(userId)
      ),
      reaction(
        () => this.entity.valid_from,
        () => this.onPossibleDaysChange()
      ),
      reaction(
        () => this.entity.valid_to,
        () => this.onPossibleDaysChange()
      ),
      reaction(
        () => this.entity.capacity_id,
        () => this.onCapacityChange()
      ),
      reaction(
        () => this.entity.project_parent_id,
        () => {
          // Select fist value from children
          const childs = this.possibleChilds;

          if (childs.length > 0 && (!this.entity.project_id || !childs.find(x => x.value === this.entity.project_id))) {
            setValue(this.entity, "project_id", childs[0].value);
          }
        }
      ),
      reaction(
        () => this.entity.valid_to,
        () => this.onValidToChanged()
      )
    );
  }

  turnOffReactions(): void {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
    this.turnOffWeekAmountReaction();
  }

  turnOffWeekAmountReaction() {
    if (this.weekAmountReactionDisposer) {
      this.weekAmountReactionDisposer();
      this.weekAmountReactionDisposer = undefined;
    }
  }

  onPossibleDaysChange = () => {
    this.reloadWeekAmounts();
  };

  onEmptyDaysChange = () => {
    this.reloadWeekAmounts();
    setValue(this.entity, "possibleDays", this.possibleDays);
  };

  onCapacityChange = () => {
    const capacityTo = this.capacityOptionsVM?.items.find(capacity => capacity.id === this.entity.capacity_id);
    if (capacityTo?.valid_to) {
      this.entity.valid_to = capacityTo.valid_to;
    }

    this.reloadWeekAmounts();
  };

  onAllocationTypeChange = (allocationType: number) => {
    const typeEnum = this.enums.value("allocation_types", allocationType);

    if (!typeEnum?.allow_total) {
      setValue(this.entity.value, "week_amount", this.entity.value.week_amount || new WeekAmount());
      setValue(this.entity.value, "total_amount", undefined);

      // Clear "week_amount" base error on day value change
      if (!this.weekAmountReactionDisposer) {
        const weekAmount = this.entity.value.week_amount;
        this.weekAmountReactionDisposer = reaction(
          () => [
            weekAmount?.monday,
            weekAmount?.tuesday,
            weekAmount?.wednesday,
            weekAmount?.thursday,
            weekAmount?.friday,
            weekAmount?.saturday,
            weekAmount?.sunday,
          ],
          () => {
            this.entity.value.clearErrors();
          }
        );
      }

      this.reloadWeekAmounts();
    } else {
      setValue(this.entity.value, "week_amount", undefined);
      setValue(this.entity.value, "total_amount", this.entity.value.total_amount || null);
      this.turnOffWeekAmountReaction();
    }

    if (typeEnum?.mandatory_time) {
      setValue(this.entity, "valid_from_time", this.entity.valid_from_time || new AllocationValidFromTime());
    } else {
      setValue(this.entity, "valid_from_time", undefined);
    }
  };

  @action.bound
  onUserIdChange = (userId: number) => {
    this.userTimeRatioRepository.setId(userId);
    this.userTimeRatioOptionsVM = new OptionsVM(this.userTimeRatioRepository, "valid_from");

    this.capacityRepository.setId(userId);
    this.capacityOptionsVM = new OptionsVM(this.capacityRepository, "valid_from");
  };

  onValidToChanged = () => {
    const deal_id = this.entity.project_id;
    if (deal_id) {
      const deal_due_date = this.projectOptionsVM?.items?.find(x => x.id === deal_id)?.due_date;

      if (deal_due_date && new Date(deal_due_date) <= this.entity.valid_to) {
        setValue(this, "due_date_advance", true);
        return;
      }
    }
    setValue(this, "due_date_advance", false);
  };

  private reloadWeekAmounts() {
    if (this.entity.value.week_amount && !this.entity.id) {
      this.entity.value.week_amount = new WeekAmount();

      this.possibleDays.forEach(day => {
        this.entity.value.week_amount![day.name] = day.capacity;
      });

      setValue(this.entity.value, "week_amount", this.entity.value.week_amount);
    }
  }

  @computed
  get allocationFormLoading(): boolean {
    if (!this.possibleUsers) {
      return !this.entity?.user_id || this.isFetching;
    } else {
      return this.isFetching;
    }
  }

  @computed
  get allowDateSelection() {
    return !!this.entity?.capacity_id || !this.entity?.is_work;
  }

  @computed
  get currentCapacity(): Capacity | undefined {
    return this.capacityOptionsVM?.items.find(capacity => capacity.id === this.entity?.capacity_id);
  }

  @computed
  get allowValidFromDays(): boolean {
    const typeEnum = this.enums.value("allocation_types", this.entity.enumeration_allocation_type_id);
    /* eslint-disable-next-line no-extra-boolean-cast */
    return !!typeEnum ? !!typeEnum.mandatory_time : false;
  }

  @computed
  get allowUserSelection(): boolean {
    /* eslint-disable-next-line sonarjs/prefer-single-boolean-return */
    if (
      this.entity &&
      !this.entity.is_work &&
      this.currentUser.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING_SELF, UserRightsOperations.CREATE) &&
      !this.currentUser.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING, UserRightsOperations.CREATE)
    ) {
      return false;
    } else {
      return true;
    }
  }

  @computed
  get allowWorkingTimeRationSelection(): boolean {
    return !!this.entity?.user_id;
  }

  @computed
  get allowCapacitySelection(): boolean {
    return !!this.entity?.job_title_working_time_ratio_id;
  }

  @computed
  get possibleAllocationTypes() {
    if (!this.entity) {
      return [];
    }

    if (this.entity.is_work) {
      return this.enums.valuesForSelect("allocation_types", false, item => item.is_work === true);
    } else {
      return this.enums.valuesForSelect("allocation_types", false, item => !item.is_work);
    }
  }

  @computed
  get possibleUsers(): OptionType<number>[] {
    if (this.allocationUserOptionsVM?.items === undefined || this.allocationUserOptionsVM?.items.length === 0) {
      return [];
    }

    return this.allocationUserOptionsVM?.items
      .concat(...this.allocationUserHelperOptionsVM.items)
      .map((item: AllocationUser) => ({ label: `${item.user.first_name} ${item.user.last_name}`, value: item.user.id! }))
      .filter((item, pos, self) => pos === self.findIndex(t => t.value === item.value)); // remove duplicated values
  }

  @computed
  get allowedWorkingTimeRatios(): JobOptionType[] {
    const allowedRatios: JobOptionType[] = [];

    this.userTimeRatioOptionsVM?.items
      .filter(option => option.isActive || option.inPreparation)
      .forEach(option => {
        option.job_title_working_time_ratios.forEach(jobOption => {
          if (jobOption.ratio !== 0 && jobOption.job_title.contract?.ignore_for_allocation !== true) {
            allowedRatios.push(ViewHelpers.jopOption(option, jobOption));
          }
        });
      });

    return allowedRatios;
  }

  @computed
  get capacities(): OptionType<number>[] {
    if (!this.entity?.job_title_working_time_ratio_id) {
      return [];
    }

    const capacityToName = (item: Capacity) => {
      const start = BaseModel.formatDate(item.valid_from, CUSTOM_DATE_FORMAT);
      const stop = item.valid_to
        ? BaseModel.formatDate(item.valid_to, CUSTOM_DATE_FORMAT)
        : this.locs.ta("capacity", "indefinitely");

      return `${item.free_rate_hours}h (${start} - ${stop})`;
    };

    return this.capacityOptionsVM?.items
      .filter(item => item.job_title_working_time_ratio_id === this.entity.job_title_working_time_ratio_id)
      .map(option => ({
        label: capacityToName(option),
        value: option.id!,
      }));
  }

  @computed
  get possibleProjects(): OptionType<number>[] {
    return this.projectOptionsVM?.selectOptions;
  }

  @computed
  get possibleGroups(): OptionType<number>[] {
    return this.projectOptionsVM?.parentOptions;
  }

  @computed
  get possibleChilds(): OptionType<number>[] {
    if (this.entity?.project_parent_id) {
      return this.projectOptionsVM?.childOptions(this.entity.project_parent_id);
    } else {
      return [];
    }
  }

  @computed
  get numberOfPossibleDays(): number {
    let daysBetween = -1;

    // There are some situations when value of Day is offseted by timezone
    // We need to use startOfDay
    const dateValidFrom = startOfDay(this.entity.valid_from);
    const dateValidTo = startOfDay(this.entity.valid_to);

    // We are checking then timezone with this values
    if (dateValidFrom?.getTime() <= dateValidTo?.getTime()) {
      daysBetween = Math.abs(differenceInDays(dateValidFrom, dateValidTo));
    }

    return daysBetween;
  }

  @computed
  get capacityAggregate(): Days {
    const capacities = this.capacityOptionsVM?.items;
    const job_title_working_time_ratio_id = this.entity.job_title_working_time_ratio_id;
    const capacity = capacities.find(cap => cap.job_title_working_time_ratio_id === job_title_working_time_ratio_id);

    if (capacity !== undefined) {
      return capacity;
    }

    const aggregate_capacity = { monday: 0, tuesday: 0, wednesday: 0, thursday: 0, friday: 0, saturday: 0, sunday: 0 };

    capacities
      .filter(cap => {
        return (
          (cap.valid_to === null || (cap.valid_to && cap.valid_to >= this.entity.valid_to)) &&
          !cap.job_title_working_time_ratio.job_title.contract.ignore_for_allocation
        );
      })
      .forEach(cap => {
        this.allDays.forEach(day => {
          aggregate_capacity[day] += cap[day];
        });
      });

    return aggregate_capacity;
  }

  @computed
  get possibleDays(): PossibleDay[] {
    const daysBetween = this.numberOfPossibleDays;
    const capacity = this.capacityAggregate;
    let daysPossible: PossibleDay[];

    if (daysBetween <= 6 && daysBetween >= 0) {
      daysPossible = eachDayOfInterval({
        start: startOfDay(this.entity.valid_from),
        end: startOfDay(this.entity.valid_to),
      }).map(day => {
        const dayEng: string = day.toLocaleString("en-us", { weekday: "long" }).toLowerCase();
        return {
          name: dayEng,
          date: day,
          capacity: capacity[dayEng],
        };
      });
    } else if (daysBetween < 0) {
      daysPossible = [];
    } else {
      daysPossible = this.allDays.map(day => ({ name: day, date: undefined, capacity: capacity[day] }));
    }

    return daysPossible.filter(day => {
      return this.hide_empty_capacities ? day.capacity !== 0 : true;
    });
  }

  @computed
  get daysSectionInvisible() {
    if (!this.entity || !this.entity.enumeration_allocation_type_id) {
      return undefined;
    }

    return this.enums.value("allocation_types", this.entity.enumeration_allocation_type_id)?.allow_total;
  }

  @computed
  get userHasNoCapacities() {
    return this.entity && this.entity.user_id && this.capacityOptionsVM?.items.length === 0;
  }

  @computed
  get noCapacitiesValidFrom() {
    return (
      this.entity &&
      this.entity.valid_from &&
      !this.capacityOptionsVM?.items.some(item => item.valid_from && item.valid_from <= this.entity.valid_from)
    );
  }

  @computed
  get isFetching(): boolean {
    let isFetching = false;

    if (this.userTimeRatioOptionsVM !== undefined) {
      isFetching = isFetching || !this.userTimeRatioOptionsVM.isInitialized;
    }

    if (this.capacityOptionsVM !== undefined) {
      isFetching = isFetching || !this.capacityOptionsVM.isInitialized;
    }

    if (this.projectOptionsVM !== undefined) {
      isFetching = isFetching || !this.projectOptionsVM.isInitialized;
    }

    if (this.allocationUserHelperOptionsVM !== undefined) {
      isFetching = isFetching || !this.allocationUserHelperOptionsVM.isInitialized;
    }

    if (this.allocationUserOptionsVM !== undefined) {
      isFetching = isFetching || !this.allocationUserOptionsVM.isInitialized;
    }

    return isFetching;
  }

  isExcessiveDay(day: string): boolean {
    if (!this.entity || !this.entity.value.week_amount) return false;

    const capacitiesForDay = this.capacities.filter(range => {
      return this.entity.value.week_amount![day] > range[day];
    });

    return !!capacitiesForDay.length;
  }

  @action.bound
  onDayValueChanged(v: number, day: string) {
    if (this.entity.is_work) return;

    const minAmount = this.enums.value("allocation_types", this.entity.enumeration_allocation_type_id)?.minimum_amount || 0;

    if (minAmount <= 0) return;

    if (v < 0 || (minAmount - 1 <= v && v < minAmount)) {
      this.entity.value.week_amount![day] = 0;
    }

    if (v > 0 && v < minAmount - 1) {
      this.entity.value.week_amount![day] = minAmount;
    }
  }

  @action.bound
  minMaxInDayValidation(v: number, day: string) {
    if (v < 0) {
      this.entity.value.week_amount![day] = 0;
    }

    if (v > 16) {
      this.entity.value.week_amount![day] = 16;
    }
  }
}
