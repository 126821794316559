import axios from "axios";
import { injectable } from "inversify";

import User from "@model/User";
import BaseRepository from "./BaseRepository";

import ApiClient, { AxiosConfig } from "@util/ApiClient";

@injectable()
export default class UserRepository extends BaseRepository<User> implements IUserRepository<User> {
  constructor() {
    super(User, "users", "user");
  }

  getAvatar(userId: number): Promise<string | undefined> {
    const config: AxiosConfig = {
      url: `${this.uri}/${userId}/avatar`,
      method: "GET",
      id: "GET_AVATAR",
      ignoreErrors: true,
      loading: false,
    };

    return ApiClient.fetchData(config)
      .then(response => {
        return `${axios.defaults.baseURL}${this.uri}/${userId}/avatar`;
      })
      .catch(error => {
        return undefined;
      });
  }

  uploadAvatar(userId: number, file: File) {
    const data: FormData = new FormData();
    data.append("file", file);

    const config: AxiosConfig = {
      url: `${this.uri}/${userId}/avatar`,
      method: "PUT",
      id: "UPLOAD_AVATAR",
      data,
    };

    return ApiClient.fetchResponse(config);
  }

  deleteAvatar(userId: number) {
    const config: AxiosConfig = {
      url: `${this.uri}/${userId}/avatar`,
      method: "DELETE",
      id: "DELETE_AVATAR",
    };

    return ApiClient.fetchResponse(config);
  }
}
