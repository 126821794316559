import { Exclude, Expose, Transform, TransformationType, Type } from "@eman/class-transformer";
import { computed, observable } from "mobx";

import BaseModel from "@model/BaseModel";
import BaseUser from "@model/BaseUser";
import Contract from "@model/Contract";
import ForeignPersonalDetail from "@model/ForeignPersonalDetail";
import PaymentInfo from "@model/PaymentInfo";
import PersonalDetail from "@model/PersonalDetail";
import PersonalDocument from "@model/PersonalDocument";
import PreviousEmployment from "@model/PreviousEmployment";
import { enumValue } from "@util/Enumerable";

export enum UserState {
  CREATED = "created",
  PROBATION = "probation",
  ACTIVE = "active",
  DISMISSAL = "dismissal",
  CLOSED = "closed",
}

export enum UserStateByContract {
  CREATED = "created",
  PROBATION = "probation",
  ACTIVE = "active",
  DISMISSAL = "dismissal",
  CLOSED = "closed",
}

export default class User extends BaseUser {
  @observable last_name_at_birth: string;
  @observable degree_before1: string;
  @observable degree_before2: string;
  @observable degree_after: string;
  @observable role_id: number;
  @observable employee_number: string;
  @observable redmine_uuid: string;
  @observable state: UserState;

  // Enum attributes
  @observable enumeration_citizenship_id = 1;

  // Child entities
  @Exclude({ toPlainOnly: true })
  @Transform(value => BaseModel.parseType<PersonalDetail>(value, PersonalDetail), { toClassOnly: true })
  @observable
  personal_detail: PersonalDetail = new PersonalDetail();

  @Expose({ name: "personal_detail_attributes", toPlainOnly: true })
  get personalDetailAttributes() {
    return this.personal_detail;
  }

  @Exclude({ toPlainOnly: true })
  @Transform((value, _obj, type) =>
    type === TransformationType.PLAIN_TO_CLASS
      ? BaseModel.parseType<ForeignPersonalDetail>(value, ForeignPersonalDetail)
      : BaseModel.formatType(value)
  )
  @observable
  foreign_personal_detail?: ForeignPersonalDetail = new ForeignPersonalDetail();

  @Expose({ name: "foreign_personal_detail_attributes", toPlainOnly: true })
  get foreignPersonalDetailAttributes() {
    return this.foreign_personal_detail;
  }

  @Exclude({ toPlainOnly: true })
  @Type(() => PersonalDocument)
  @observable
  personal_documents: PersonalDocument[] = [];

  @Exclude({ toPlainOnly: true })
  @Type(() => PreviousEmployment)
  @observable
  previous_employments: PreviousEmployment[] = [];

  @Exclude({ toPlainOnly: true })
  @Type(() => PaymentInfo)
  @observable
  payment_infos: PaymentInfo[] = [];

  @Exclude({ toPlainOnly: true })
  @Type(() => Contract)
  @observable
  main_contract: Contract;

  // Enums
  @Exclude()
  @enumValue("citizenships", "enumeration_citizenship_id")
  @observable
  citizenship: models.IEnumType;

  isDirtyRelations(): string[] {
    return ["personal_detail", "foreign_personal_detail"];
  }

  @computed
  get fullName(): string {
    const middleName = this.middle_name ? ` ${this.middle_name}` : "";
    return `${this.first_name}${middleName} ${this.last_name}`;
  }
}
