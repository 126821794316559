import { Transform, Type } from "@eman/class-transformer";
import { computed, observable } from "mobx";

import BaseModel from "./BaseModel";

export default class EnumType extends BaseModel implements models.IEnumType {
  @observable id: number;
  @observable name: string;
  @observable description: string;
  @observable code: string;
  @observable options?: any;
  @observable order: number | null;
  @observable deleted: boolean;

  // Only social_security_offices attribute
  @observable district?: string;

  // Only 'working_times' and 'rate_item_types' attribute
  @observable coeficient?: number;

  // Only citizenship enum attribute
  @observable eurozone?: boolean;

  // Only contract_types attribute
  @observable supergross?: boolean;

  // Only rate_item_types enum attribute
  @observable calculation?: boolean;

  // Only allocation_types enum attribute
  @observable is_work?: boolean;
  @observable allow_total?: boolean;
  @observable mandatory_time?: boolean;
  @observable minimum_amount?: number;
  @observable minimum_auto_reply_day?: number;
  @observable auto_reply?: boolean;
  @observable reply_body?: string;
  @observable reply_subject?: string;

  // Only training_types enum attribute
  @observable supervisor_id?: number;
  @observable job_positions?: number[];
  @observable url?: string;

  // Only evidence_state_reasons enum attribute
  @observable event?: string;
  @observable before_status?: string;
  @observable after_status?: string;
  @observable allowed_user_types?: string[];

  // Only supergrossess attributes
  @observable value?: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  @computed get valid(): boolean {
    return !this.deleted;
  }
}
