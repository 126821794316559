import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import School from "@model/School";
import { AutoCreateBox, CheckBox, DatePickerBox, TextBox } from "@util/Form";
import SchoolFormVM from "@vm/Form/School";

interface SchoolFormProps {
  vm: SchoolFormVM;
}

@observer
export default class SchoolForm extends BaseForm<School, SchoolFormProps> {
  modelName = "school";
  prefix = "employee.personal_information";

  renderFormBody() {
    const { schoolOptions, createNewSchool } = this.props.vm;
    const createCardConfig = { message: this.tg("not_found"), createButtonLabel: this.tg("add_school") };

    return (
      // TODO: delete style when date picker will be fixed
      <div style={{ minHeight: 400 }}>
        <div className="row">
          <div className="col-md-6">
            {this.generateFormField(
              "enumeration_school_id",
              <AutoCreateBox
                createCardConfig={createCardConfig}
                options={schoolOptions}
                onCreateOption={createNewSchool}
                emptyRow={this.emptyRow}
                placeholder={this.tg("select_school")}
              />,
              true
            )}
          </div>
          <div className="col-md-6">{this.generateFormField("field", <TextBox />, true)}</div>
          <div className="col-md-6">{this.generateFormField("degree", <TextBox />, true)}</div>
          <div className="col-md-6">{this.generateFormField("completed", <CheckBox />, true)}</div>
          <div className="col-md-6">
            {this.generateFormField("date_from", <DatePickerBox fromMonth={this.props.vm.fromDate} />, true)}
          </div>
          <div className="col-md-6">
            {this.generateFormField("date_to", <DatePickerBox fromMonth={this.props.vm.toDate} />, true)}
          </div>
        </div>
      </div>
    );
  }
}
