import { observer } from "mobx-react";
import React from "react";
import { Prompt } from "react-router";

import Columns from "@eman/emankit/Columns";
import Contract, { ContractType } from "@model/Contract";

import BaseForm from "@component/BaseForm";
import { CheckBox, DatePickerBox, SelectBox, TextBox } from "@util/Form";
import ContractFormVM from "@vm/Form/Contract";
import { computed } from "mobx";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

export interface ContractFormProps {
  vm: ContractFormVM;
  validFromDisabled?: boolean;
}

@observer
export default class ContractForm extends BaseForm<Contract, ContractFormProps> {
  modelName = "contract";
  prefix = "employee";

  parseNumber = (value: string) => {
    return parseInt(value, 10);
  };

  @computed
  get canCreateToUnlimitedPast(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  employmentContractFields = (): React.ReactNode => {
    const fields = [
      this.generateFormField(
        "enumeration_user_type_id",
        <SelectBox options={this.enums.valuesForSelect("user_types")} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField(
        "enumeration_contract_type_id",
        <SelectBox options={this.props.vm.contractTypesOptions} emptyRow={this.emptyRow} type="number" />,
        true
      ),
      this.generateFormField(
        "company_id",
        <SelectBox parseValue={this.parseNumber} options={this.props.vm.companyOptions} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField(
        "branch_office_id",
        <SelectBox options={this.props.vm.branchOfficeOptions} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField(
        "enumeration_currency_id",
        <SelectBox options={this.enums.valuesForSelect("currencies")} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField(
        "enumeration_payment_method_id",
        <SelectBox options={this.enums.valuesForSelect("payment_methods")} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField("payment_info_id", <SelectBox options={this.props.vm.paymentInfoOptions} allowEmpty={true} />),
      this.generateFormField("contract_id", <SelectBox options={this.props.vm.contractOptions} allowEmpty={true} />),
      this.generateFormField("primary", <CheckBox />),
      this.generateFormField(
        "valid_from",
        <DatePickerBox
          dateFrom={this.canCreateToUnlimitedPast ? undefined : this.props.vm.validFromMinimumDate}
          disabledDays={{ before: this.canCreateToUnlimitedPast ? undefined : this.props.vm.validFromMinimumDate }}
          disabledInput={this.props.validFromDisabled}
        />,
        true
      ),
      this.generateFormField(
        "working_from",
        <DatePickerBox
          dateFrom={this.props.entity.valid_from}
          disabledDays={{ before: this.props.entity.valid_from }}
          disabledInput={!this.props.entity.valid_from}
        />
      ),
      this.generateFormField(
        "signed_at",
        <DatePickerBox
          dateTo={this.props.entity.valid_from}
          disabledDays={{ after: this.props.entity.valid_from }}
          disabledInput={!this.props.entity.valid_from}
        />
      ),
      this.generateFormField(
        "probation_period_to",
        <DatePickerBox
          dateFrom={this.props.entity.valid_from}
          disabledDays={{ before: this.props.entity.valid_from }}
          disabledInput={!this.props.entity.valid_from}
        />
      ),
      this.generateFormField("notice_period_months", <TextBox type="number" />),
    ];

    if (this.props.entity.primary === false) {
      fields.splice(9, 0, this.generateFormField("ignore_for_allocation", <CheckBox />));
    }

    // tslint:disable-next-line:triple-equals
    if (this.props.entity.enumeration_contract_validity_type_id == 1) {
      fields.splice(
        10,
        0,
        this.generateFormField(
          "end_at",
          <DatePickerBox dateFrom={this.props.entity.valid_from} disabledDays={{ before: this.props.entity.valid_from }} />,
          true
        )
      );
    }

    if (
      [ContractType.DPP, ContractType.ICO, ContractType.SP].indexOf(this.props.entity.contract_type?.code as ContractType) !== -1
    ) {
      fields.splice(
        12,
        0,
        this.generateFormField(
          "insurance_from",
          <DatePickerBox disabledInput={this.props.entity.contract_type.code !== ContractType.SP} />
        )
      );
    }

    if (
      [ContractType.DPP, ContractType.ICO, ContractType.SP].indexOf(this.props.entity.contract_type?.code as ContractType) !== -1
    ) {
      fields.splice(
        13,
        0,
        this.generateFormField(
          "insurance_to",
          <DatePickerBox
            dateFrom={this.props.entity.insurance_from}
            disabledDays={{ after: this.props.entity.insurance_from }}
            disabledInput={this.props.entity.contract_type.code !== ContractType.SP}
          />
        )
      );
    }

    //  Contract validity field is created last, because of fixed position top right corner of the form

    fields.splice(
      10,
      0,
      this.generateFormField(
        "enumeration_contract_validity_type_id",
        <SelectBox options={this.enums.valuesForSelect("contract_validity_types")} allowEmpty={true} />
      )
    );

    return fields;
  };

  renderFormBody() {
    const { entity } = this.props;

    return (
      <>
        <Columns byColumns={true} firstColumnLimit={8}>
          {this.employmentContractFields()}
        </Columns>
        <Prompt when={entity.changes > 0} message={this.locs.tg("form.unsaved_prompt")} />
      </>
    );
  }
}
