import BaseList from "@component/BaseList";

import Badge from "@eman/emankit/Badge";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors";
import { IconType } from "@eman/emankit/Icon";
import Company from "@model/Company";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import OrganizationStructureListVM from "@vm/List/OrganizationStructure";
import { observer } from "mobx-react";
import React from "react";

import { ORGANIZATION_STRUCTURE_TYPE_PARAM } from "../../../config";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class OrganizationStrictureList extends BaseList<OrganizationStructure, OrganizationStructureListVM> {
  modelName = "organization_structure";
  prefix = "employer";
  searchable = false;

  @lazyInject(TYPES.OrganizationStructureList)
  vm: OrganizationStructureListVM;

  onBranchOfficesClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = parseInt(e.currentTarget.getAttribute("data-id")!, 10);

    if (id) {
      this.router.pageLink(this.uriHelper.employer_organization_structures_branch_offices(id));
    }
  };

  onOpenChildOrganizations = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = parseInt(e.currentTarget.getAttribute("data-id")!, 10);
    if (!this.vm.openedChilds.includes(id)) {
      this.vm.openedChilds.push(id);
    } else {
      const filter = this.vm.openedChilds.filter(id => id !== id);
      this.vm.openedChilds = filter;
    }
    this.forceUpdate();
  };

  renderRow = (item: OrganizationStructure, column: string) => {
    switch (column) {
      case "action":
        if (this.user.allowToObject(UserRightsObjects.BRANCH_OFFICE, UserRightsOperations.LIST)) {
          return (
            <>
              {!(item instanceof Company) && item.children_exist && (
                <Button
                  data-id={item.id}
                  variant={ButtonVariant.Active}
                  icon={item.id && !this.vm.openedChilds.includes(item.id) ? IconType.ArrowBottom : IconType.ArrowTop}
                  title={this.tg("branch_office.title")}
                  onClick={this.onOpenChildOrganizations}
                />
              )}
              {item instanceof Company && this.user.allowToObject(UserRightsObjects.BRANCH_OFFICE) && (
                <Button
                  data-id={item.id}
                  onClick={this.onBranchOfficesClick}
                  variant={ButtonVariant.Active}
                  icon={IconType.Eman}
                  title={this.tg("branch_office.title")}
                />
              )}
            </>
          );
        } else {
          return null;
        }

      case "parent":
        return item.parent ? item.parent.name : null;
      case "child":
        return (
          <>
            {item.id && this.vm.openedChilds.includes(item.id) && (
              <>
                {this.vm.getOrganizationUnitChild(item.id)?.children.map(item => (
                  <p key={item.id}>{item.name}</p>
                ))}
              </>
            )}
          </>
        );

      case "type":
        return (
          <Badge color={item.company ? BaseColor.Green : BaseColor.Orange}>
            {this.locs.tm(item.company ? "company" : "organization_unit", 1)}
          </Badge>
        );

      default:
        return item[column];
    }
  };

  onAddUnitClick = () => {
    this.router.pageLink(this.uriHelper.new_employer_organization_structures());
  };

  onEditClick = (item: OrganizationStructure) => {
    if (this.user.allowToObject(UserRightsObjects.ORGANIZATION_STRUCTURE, UserRightsOperations.EDIT)) {
      this.router.pageLink(this.uriHelper.edit_employer_organization_structures(item.id), {
        [ORGANIZATION_STRUCTURE_TYPE_PARAM]: item.type,
      });
    } else if (this.user.allowToObject(UserRightsObjects.ORGANIZATION_STRUCTURE, UserRightsOperations.SHOW)) {
      this.router.pageLink(this.uriHelper.show_employer_organization_structures(item.id), {
        [ORGANIZATION_STRUCTURE_TYPE_PARAM]: item.type,
      });
    }
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.ORGANIZATION_STRUCTURE, UserRightsOperations.CREATE)) {
          return (
            <Button icon={IconType.Plus} variant={ButtonVariant.Primary} onClick={this.onAddUnitClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "organization_structure_id",
        label: this.locs.tm("organization_unit"),
        values: this.vm.organizationUnitOptions,
        hasChildren: true,
      },
      {
        id: "parent_id",
        columnId: "parent",
        label: this.ta("parent"),
        values: this.vm.parentOptions,
        single: false,
      },
      {
        id: "type",
        label: this.ta("type"),
        values: [
          { value: OrganizationStructureType.COMPANY, label: this.locs.tm("company", 1) },
          { value: OrganizationStructureType.ORGANIZATION_UNIT, label: this.locs.tm("organization_unit", 1) },
        ],
        single: true,
      },
    ];

    return {
      filters,
    };
  }

  dataProps() {
    const columns = [
      this.createField("action", {
        hideLabel: true,
        width: 30,
        nosort: true,
        childrenOpener: true,
        alwaysShow: true,
        className: "noclick",
      }),
      this.createField("name", {}),
      this.createField("parent", { nosort: true }),
      this.createField("child", { nosort: true }),
      this.createField("type", { nosort: true }),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: this.onEditClick,
    };
  }
}
